import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import { chunk } from 'lodash';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import EventListing from '~/components/Blog/EventListing';
import StaggeredGrid from '~/components/Blog/StaggeredGrid';
import BlogLayout from '~/components/BlogLayout';
import SEO from '~/components/Global/SEO';
interface BlogCategoryProps {
  data: {
    prismicCategory: {
      data: {
        colour: string;
        title: { text: string };
        hero_image: { fluid: object };
        hero_description: string;
      };
    };
    allPrismicBlogPost: {
      nodes: [];
    };
  };
  pageContext: {
    uid: string;
  };
}

const BlogCategory: FunctionComponent<BlogCategoryProps> = ({
  data,
  pageContext,
}): ReactElement => {
  const {
    colour,
    title,
    hero_image,
    hero_description,
  } = data.prismicCategory.data;

  const posts = data.allPrismicBlogPost.nodes;

  const categorySlug = pageContext.uid;
  const [postShown, setPostShown] = useState(10);
  const chunks = chunk(posts, 5);
  const showMorePosts = () => setPostShown(postShown + 10);

  return (
    <BlogLayout>
      <SEO title={title.text} />
      <section
        className="overflow-hidden hero bg-blog-purple"
        style={{ backgroundColor: colour }}
      >
        <div className="container">
          <div className="grid items-center md:grid-cols-2">
            <div className="h-screen -mx-8 md:mx-0">
              <div className="h-full xl:-ml-72 md:-ml-32 bg-grey">
                <Image
                  fluid={hero_image.fluid}
                  className="object-cover w-full h-full"
                />
              </div>
            </div>

            <div className="flex flex-col justify-between py-16 text-center md:py-0 xl:-mr-8">
              <h1 className="max-w-sm mx-auto font-semibold font-blog-serif u-h3 lg:u-h1">
                {title.text}
              </h1>

              <div
                className="mx-auto my-6 bg-black cat-hero-line"
                style={{ height: '14vh', width: '1px' }}
              />

              <div className="max-w-xs mx-auto leading-normal text-18px">
                {hero_description.text}
              </div>
            </div>
          </div>
        </div>
      </section>

      <main className="py-12 md:py-24 md:pt-12">
        {categorySlug !== 'events' ? (
          <>
            {chunks.map((chunk, i) => (
              <section key={`chunk-${i}`} className="mar-tb">
                <div className="container">
                  <StaggeredGrid reversed={i % 2 === 0} posts={chunk} />
                </div>
              </section>
            ))}
          </>
        ) : (
          <section className="mt-12 mb-12 events md:mt-24">
            <div className="container">
              <div>
                {posts.map((post, i) => (
                  <EventListing {...post} key={`event-${i}`} />
                ))}
              </div>
            </div>
          </section>
        )}

        <div className="text-center">
          {posts.length > postShown && (
            <button
              onClick={() => showMorePosts()}
              className="w-48 mx-auto bg-white border btn border-blog-primary text-blog-primary"
            >
              View more
            </button>
          )}
        </div>
        {!posts.length && (
          <div className="container mt-12 text-center">
            <h2 className="font-blog-serif">No posts found</h2>
          </div>
        )}
      </main>
    </BlogLayout>
  );
};

export const query = graphql`
  query($id: String!, $uid: String!) {
    prismicCategory(id: { eq: $id }) {
      data {
        title {
          text
        }
        colour
        hero_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_description {
          text
        }
      }
    }

    allPrismicBlogPost(filter: { data: { category: { uid: { eq: $uid } } } }) {
      nodes {
        ...PostPreview
      }
    }
  }
`;

export default BlogCategory;
